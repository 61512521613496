<template>
  <div>
    <b-modal ref="my-modal" class="rounded-0" centered :title="`Menu Güncelle ( ${form.baslik} )`">
      <b-card-text>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Alt Kategoriler Menüde Göster">
              <v-select
                v-model="form.kategori"
                :options="durumlar"
                :reduce="(durumlar) => durumlar.id"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Sayfaları Menüde Göster">
              <v-select
                v-model="form.sayfa"
                :options="durumlar"
                :reduce="(durumlar) => durumlar.id"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Turları Menüde Göster">
              <v-select
                v-model="form.turlar"
                :options="durumlar"
                :reduce="(durumlar) => durumlar.id"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Galeri Menüde Göster">
              <v-select
                v-model="form.galeri"
                :options="durumlar"
                :reduce="(durumlar) => durumlar.id"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Yeni Sekmede Göster">
              <v-select
                v-model="form.sekme"
                :options="durumlar"
                :reduce="(durumlar) => durumlar.id"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" @click="handlerMenuGuncelle" :disabled="show">
            <feather-icon class="cursor-pointer" icon="SaveIcon" size="16" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            Kaydet
          </b-button>
          <b-button squared variant="danger" class="float-right mr-1" @click="handlerMenuSil(form.k_no)" :disabled="showDel">
            <feather-icon class="cursor-pointer" icon="Trash2Icon" size="16" v-if="!showDel" />
            <b-spinner class="mr-25" small variant="light" v-else />
            Sil
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import vSelect from 'vue-select';

import { ref, toRefs, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';

export default {
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    expo.showDel = ref(false);
    expo.form = ref({
      _id: null,
      k_no: null,
      baslik: null,
      kategori: 0,
      sayfa: 0,
      turlar: 0,
      galeri: 0,
      sekme: 0,
    });
    expo.durumlar = ref([
      {
        id: 0,
        title: 'Hayır',
      },
      {
        id: 1,
        title: 'Evet',
      },
    ]);

    expo.toggleModal = () => {
      context.refs['my-modal'].toggle('#toggle-btn');
      context.emit('closeModal', true);
    };
    expo.handlerMenuGuncelle = () => {
      expo.show.value = true;
      store
        .dispatch('menuGuncelle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: expo.form.value._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            expo.toggleModal();
            expo.show.value = false;
          }
        })
        .catch((err) => {
          expo.show.value = false;
          if (err.data.success == false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };
    expo.handlerMenuSil = (k_no) => {
      expo.showDel.value = true;
      store
        .dispatch('menuSil', k_no)
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: 'Silme Başarılı.',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            expo.toggleModal();
            expo.showDel.value = false;
          }
        })
        .catch((err) => {
          expo.showDel.value = false;
          if (err.data.success == false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    const { openModal, updateData } = toRefs(props);

    watch(
      openModal,
      (val) => {
        if (val == true) {
          expo.form.value._id = updateData.value._id;
          expo.form.value.k_no = updateData.value.k_no;
          expo.form.value.baslik = updateData.value.baslik;
          expo.form.value.kategori = updateData.value.kategori;
          expo.form.value.sayfa = updateData.value.sayfa;
          expo.form.value.turlar = updateData.value.turlar;
          expo.form.value.sekme = updateData.value.sekme;
          expo.toggleModal();
        }
      },
      { immediate: true }
    );
    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
