<template>
  <div>
    <b-card-normal>
      <template v-slot:body>
        <b-list-group class="rounded-0" horizontal="md">
          <b-list-group-item class="rounded-0" disabled>Anasayfa</b-list-group-item>
          <draggable
            v-model="menuler"
            handle=".draggable-task-handle"
            class="list-group list-group-horizontal"
            tag="ul"
            @end="handlerMenuChange"
          >
            <b-list-group-item class="rounded-0" v-for="menu in menuler" :key="menu.k_no" tag="li">
              <feather-icon class="cursor-move draggable-task-handle mr-25" icon="MoveIcon" size="18" />
              <feather-icon class="cursor-pointer" icon="SettingsIcon" size="18" @click="handlerUpdate(menu)" />
              | {{ menu.baslik }}
            </b-list-group-item>
          </draggable>
          <b-list-group-item class="rounded-0" disabled>İletişim</b-list-group-item>
        </b-list-group>
      </template>
    </b-card-normal>

    <menu-guncelle :updateData="updateData" :openModal="openModal" @closeModal="closeModal = $event" />
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import draggable from 'vuedraggable';
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import MenuGuncelle from './menuGuncelle.vue';

import { ref, watch, computed, toRefs } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';

export default {
  components: {
    BCardNormal,
    draggable,
    MenuGuncelle,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: [],
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.openModal = ref(false);
    expo.closeModal = ref(false);
    expo.updateData = ref({});
    expo.drag = ref(false);
    expo.menuler = ref([]);

    const { data } = toRefs(props);

    expo.handlerUpdate = (event) => {
      expo.updateData.value = event;
      expo.openModal.value = true;
    };

    expo.handlerMenuChange = () => {
      console.log(expo.menuler.value);
      store
        .dispatch('menuSiraGuncelle', { props: expo.menuler.value })
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: 'Sıra Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            context.emit('update', res.data.data);
          }
        })
        .catch((err) => {
          if (err.data.success == false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    watch(
      expo.closeModal,
      (val) => {
        if (val) {
          expo.openModal.value = false;
          expo.closeModal.value = false;
          expo.updateData.value = {};
          context.emit('update', expo.updateData.value);
        }
      },
      { deep: true }
    );

    watch(
      data,
      (val) => {
        if (val) {
          expo.menuler.value = val;
        }
      },
      {
        deep: true,
      }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped>
.bg-ozel {
  background-color: #dadada;
}
</style>
