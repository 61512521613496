<template>
  <div>
    <b-card-normal :title="props.title">
      <template v-slot:body>
        <vue-perfect-scrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings" class="scroll-area">
          <b-alert variant="warning" class="text-center rounded-0" show v-if="data.length == 0">
            <h4 class="alert-heading">Opss !!</h4>
            <div class="alert-body">Gösterilecek {{ props.title }} bulunamadı.</div>
          </b-alert>
          <b-list-group class="rounded-0" v-else>
            <b-list-group-item class="m-0 p-0" v-for="item in data" :key="item._id">
              <div class="d-flex justify-content-start align-content-start">
                <span
                  class="p-1 border-right"
                  v-b-tooltip.hover.top="'Üst Menüye Ekle'"
                  @click="$emit('menuEkle', { item, type: props.type, location: 'top' })"
                >
                  <feather-icon class="cursor-pointer" icon="ChevronsUpIcon" size="24" />
                </span>
                <span
                  class="p-1 border-right"
                  v-b-tooltip.hover.top="'Alt Menüye Ekle'"
                  @click="$emit('menuEkle', { item, type: props.type, location: 'bottom' })"
                >
                  <feather-icon class="cursor-pointer" icon="ChevronsDownIcon" size="24" />
                </span>
                <span class="p-1">{{ item.baslik }}</span>
              </div>
            </b-list-group-item>
          </b-list-group>
        </vue-perfect-scrollbar>
      </template>
    </b-card-normal>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
export default {
  components: {
    BCardNormal,
    VuePerfectScrollbar,
  },
  props: {
    props: {
      type: Object,
      required: true,
      Default: {},
    },
    data: {
      type: Array,
      required: true,
      default: [],
    },
  },
  data: () => ({
    perfectScrollbarSettings: {
      maxScrollbarLength: 150,
      wheelPropagation: false,
    },
  }),
};
</script>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
  height: 400px;
}
</style>
